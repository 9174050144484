<template>
  <div class="page">
    <Navbar title="上学预约" type="SMP" />
    <div class="records">
      <van-tabs :active="tabActive" :color="COLOR_M" border="true">
        <van-tab title="进行中" name="order">
          <van-row
            class="record bdc-b1s-gray"
            v-for="order in orderList"
            :key="order.code"
          >
            <van-col span="16" class="content">
              <div class="title">
                {{ order.taskName }}
              </div>
              <div class="time">单号：{{ order.code }}</div>
            </van-col>
            <van-col span="8">
              <div class="amount color-s1">
                <van-tag :color="COLOR_M" plain v-if="order.node === 'FVF'"
                  >待初审</van-tag
                >
                <van-tag :color="COLOR_M" plain v-if="order.node === 'RVF'"
                  >待复审</van-tag
                >
                <van-tag :color="COLOR_M" plain v-if="order.node === 'PAY'"
                  >待支付</van-tag
                >
                <van-tag :color="COLOR_M" plain v-if="order.node === 'TST'"
                  >待办理</van-tag
                >
                <van-tag :color="COLOR_M" plain v-if="order.node === 'CSG'"
                  >待交付</van-tag
                >
              </div>
              <div class="btn">
                <van-tag
                  :color="COLOR_M"
                  v-if="order.node !== 'PAY'"
                  @click="remind()"
                  ><van-icon name="bulb-o" /> 催单</van-tag
                >
                <van-tag
                  type="warning"
                  v-if="order.node === 'PAY'"
                  @click="pay(order.amount, order.code)"
                  ><van-icon name="shopping-cart-o" /> 支付</van-tag
                >
              </div>
            </van-col>
          </van-row>
          <van-row class="no-record" v-if="orderList.length <= 0">
            <van-col span="24">
              <van-image
                width="103"
                height="103"
                :src="require('../../../../assets/images/home/no-record.png')"
              ></van-image
            ></van-col>
          </van-row>
        </van-tab>
        <van-tab title="已完成" name="settle"> </van-tab>
      </van-tabs>
    </div>
    <Loading :show="loadingShow" />
    <Tabbar active="own" />
    <Share ref="share" />
  </div>
</template>
<script>
import Tabbar from '../common/Tabbar.vue'
import Loading from '../../common/Loading.vue'
import Share from '../../common/Share.vue'
import Navbar from '../../common/Navbar.vue'
import { Tag, Icon, Tabs, Tab, Image, Cell } from 'vant'
export default {
  components: {
    Tabbar: Tabbar,
    Loading: Loading,
    Share: Share,
    Navbar: Navbar,
    [Tag.name]: Tag,
    [Icon.name]: Icon,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Image.name]: Image,
    [Cell.name]: Cell
  },
  data () {
    return {
      loadingShow: false,
      orderList: [],
      refereeList: [],
      amount: 0,
      tabActive: 'order',
      orderCode: '2',
      settle: { prior: 0, wait: 0, complete: 0 },
      payOrderCode: '1'
    }
  },
  mounted () {
    var token = window.sessionStorage.getItem(this.SESSION_TOKEN)
    if (token !== '' && token !== null && token !== undefined) {
      this.retrieveOrderList()
      this.retrieveRefereeList()
      this.retrieveSettleCount()
    } else {
      this.$router.push({ path: '/bind' })
    }
    this.$refs.share.default('main')
  },
  methods: {
    async retrieveOrderList () {
      this.loadingShow = true
      var pd = { current: 1, size: 100 }
      const { data: res } = await this.$http.post(this.BMS_URL + '/enroll/order/retrieveOrderUserList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.orderList = res.data
      }
      this.loadingShow = false
    },
    async retrieveSettleCount () {
      var user = window.sessionStorage.getItem(this.SESSION_USER)
      var pd = { refereeCode: user }
      const { data: res } = await this.$http.post(this.BMS_URL + '/overall/orderSET/retrieveOrderSETRefereeCount', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.settle = res.data
      }
    },
    async retrieveRefereeList () {
      this.loadingShow = true
      var user = window.sessionStorage.getItem(this.SESSION_USER)
      var pd = { refereeCode: user, current: 1, size: 100 }
      const { data: res } = await this.$http.post(this.BMS_URL + '/overall/orderSET/retrieveOrderSETRefereeList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.refereeList = res.data
      }
      this.loadingShow = false
    },
    remind () {
      this.$dialog.alert({
        title: '提示',
        message: '已进行催单,请耐心等待'
      })
    },
    async pay (amount, code) {
      this.loadingShow = true
      var serial = window.sessionStorage.getItem(this.SESSION_SERIAL)
      this.orderCode = code
      this.amount = amount
      var pd = {
        appid: 'wxa8145a74e5f2b39a',
        amount: this.amount * 100,
        attach: this.taskCode,
        description: '证书培训支付',
        body: '证书培训支付',
        detail: '',
        business: this.code,
        openid: serial
      }
      const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/createJsapiPay', this.$qs.stringify(pd))
      if (res.status === '200') {
        var that = this
        this.payOrderCode = res.data.code
        var param = res.data.param
        window.WeixinJSBridge.invoke(
          'getBrandWCPayRequest', param,
          function (rs) {
            if (rs.err_msg === 'get_brand_wcpay_request:ok') {
              that.retrievePayStatus()
            } else {
              that.loadingShow = false
            }
          })
      }
    },
    async retrievePayStatus () {
      var pd = { appid: 'wxa8145a74e5f2b39a', code: this.payOrderCode }
      const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/retrievePayStatus', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.updateOrderPay()
      } else {
        this.loadingShow = false
      }
    },
    async updateOrderPay () {
      var pd = { orderCode: this.orderCode, payCode: this.payOrderCode, amount: this.amount }
      var that = this
      const { data: res } = await this.$http.post(this.BMS_URL + '/overall/order/updateOrderPay', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.$dialog.confirm({
          title: '支付结果',
          message: '支付成功'
        })
          .then(() => {
            that.retrieveOrderList()
          })
      }
      this.loadingShow = false
    }
  }
}
</script>
<style lang="less" scoped>
.top {
  height: 80px;
  width: 96%;
  margin: 10px 2% 0px 2%;
  text-align: center;
  border-radius: 10px;
}
.top .item {
  height: 80px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.navigate {
  text-align: left;
  .icon {
    margin-top: 3px;
    width: 20px;
    height: 20px;
  }
  .name {
    font-size: 15px;
    height: 25px;
    line-height: 25px;
  }
}
.settle {
  padding: 10px 0px;
  background-color: #fff;
  .amount {
    font-size: 16px;
    font-weight: 600;
    padding-top: 10px;
    height: 25px;
    line-height: 25px;
  }
  .text {
    height: 18px;
    line-height: 18px;
    padding-bottom: 10px;
    color: #bababa;
  }
}
.records {
  margin: 0px 2% 0px 2%;
  .title {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    padding: 2px 5px;
    .icon {
      text-align: center;
    }
  }
  .record {
    padding: 8px 0px;
    .title {
      height: 23px;
      line-height: 23px;
      font-size: 13px;
      font-weight: 600;
      text-align: left;
      padding: 2px 5px;
    }
    .icon {
      width: 50px;
      height: 50px;
      border-radius: 60px;
    }
    .cert {
      height: 23px;
      line-height: 23px;
      padding: 1px 5px;
      text-align: left;
    }
    .code {
      height: 23px;
      line-height: 23px;
      padding: 1px 5px;
      text-align: left;
    }
    .time {
      height: 23px;
      line-height: 23px;
      padding: 1px 5px;
      text-align: left;
    }
    .amount {
      height: 23px;
      line-height: 23px;
      font-size: 13px;
      font-weight: 600;
      padding: 2px 5px;
    }
    .btn {
      height: 23px;
      line-height: 23px;
    }
    .state {
      height: 23px;
      line-height: 23px;
    }
  }
}
</style>
